import "@fontsource/montserrat";
import "./src/styles/global.css";

export const shouldUpdateScroll = () => false;

//  TO NOTE: that code workarounds this issue: https://github.com/gatsbyjs/gatsby/issues/21120
export const onRouteUpdate = ({ location }) => scrollToAnchor(location, 0);

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`)!["offsetTop"];

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: "smooth",
    });
  }

  return true;
}
