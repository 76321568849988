exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-explanations-tsx": () => import("./../../../src/pages/explanations.tsx" /* webpackChunkName: "component---src-pages-explanations-tsx" */),
  "component---src-pages-form-success-tsx": () => import("./../../../src/pages/form-success.tsx" /* webpackChunkName: "component---src-pages-form-success-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-outdated-tool-tsx": () => import("./../../../src/pages/outdated-tool.tsx" /* webpackChunkName: "component---src-pages-outdated-tool-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

